<template>
	<li :class="'_pos_'+index+' _type_bible'">
 		<div class="item_wrap">
			<span class="info_week">
				<span class="info_ep">{{chapterForm}}</span>
			</span>
			<span class="info_type">
				<span class="info_title">{{typeName}}</span>
			</span>
			<span class="info_thumb"></span>
			<span class="info_subject">
				<span class="info_title">{{bibleStr}}</span>
				<span class="info_book">{{bibleChapter}}</span>
			</span>
			<span class="info_btns">
				<a 
					:href="fileURL" 
					title="자료 열기" 
					v-on:click="preview($event, fileURL, readNo+'.'+bibleStr+' '+bibleChapter+' - '+typeName)" 
					class="link_data link_view"
				>
					{{viewFileStr}}
				</a>
				<a 
					:href="fileURL" 
					title="자료 다운로드" 
					class="link_data link_download" 
					@click.prevent="downloadItem(fileURL, fileName)"
					download
				>
					{{downloadStr}}
				</a>
			</span>
		</div>
	</li>
</template>

<script>
import axios from 'axios'
import languagePack from '@/language/readBible'
import langCommon from '@/language/common'

export default {
    name: 'DataList',
	components: {
	},
    data: () => ({
		items:[]
    }),
	props:{
		index:{type: Number},
		readNo:{type: Number},
		bookCode:{type: Number},
		fileName:{type: String, default: ''},
		fileURL:{type: String, default: ''},
		title:{type: String, default: ''}
	},
    computed: {
        lang() {
            return this.$store.state.lang;
        },
		weekStr(){
			return this.$store.state.weekForm(this.dateYear, this.dateMonth, this.dateWeek);
		},
		chapterForm(){
			return languagePack.chapterUnitForm(this.readNo)
		},
		bibleChapter(){
			return langCommon.bibleChapter(this.title);
		},
		bibleStr(){
			return this.$store.state.getBible(this.bookCode);
		},
		typeName() {
			return languagePack.typeName()
		},
		viewFileStr() {
			return langCommon.filePreview();
		},
		downloadStr() {
			return langCommon.fileDownload();
		},
    },
	methods: {
		viewItem(itemID, e){
			e.preventDefault();
			this.$emit('select-item', itemID);
		},
		preview(e, url, title) {
			e.preventDefault();
			this.$store.state.openPreview(url, title);
		},
		downloadItem (url, label) {
			axios.get(url, { responseType: 'blob' })
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' })
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = label;
				link.click();
				URL.revokeObjectURL(link.href);
			}).catch(console.error);
		}
	},
	mounted(){
	}
}
</script>
