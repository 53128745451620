<template>
	<div class="main_wrap _bi">
		<h2 class="section_title">
			<span class="main_title">{{mainTitle}}</span>
			<span class="sub_title">{{subTitle}}</span>
		</h2>
		<bible-list 
		/>
	</div>
</template>

<script>
import BibleList from '@/components/BibleList'
import languagePack from '@/language/readBible'
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
  	name: 'Bible',
	props: {
	},
	data: () => ({
	}),
	components:{
		BibleList
	},
	computed:{
		lang(){
			return this.$store.state.lang;
		},
		mainTitle(){
			return this.$store.state.headerTitle("bible", "main");
		},
		subTitle(){
			return this.$store.state.headerTitle("bible", "sub");
		},
		typeName() {
			var str=languagePack.typeName;
			return str[this.lang];
		},
		readNoUnit() {
			var str=languagePack.readNoUnit;
			return str[this.lang];
		}
	},
	methods: {
	},
	created(){
		
	},
	mounted(){
	},
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.bible[lang],
			desc: '징검다리 성경읽기는 어린이들과 부모님들을 위한 가정용 성경통독 교재입니다. 창세기부터 요한계시록까지 모든 성경을 읽기 어려운 어린이들에게 매 주일 징검다리처럼 성경을 하나하나 건너가며 각 성경의 주요 내용들을 읽고 그림으로 그려진 문제들을 풀어가면서 성경을 읽을 수 있도록 만들어졌습니다. 징검다리 성경읽기는 6년 커리큘럼으로 제작되어 성경의 거의 모든 내용들을 읽을 수 있도록 준비되었으며 매주일 업데이트 되는 교재를 하이바이블 페이지에서 무료로 다운받아서 사용하실 수 있습니다. 징검다리 성경 읽기와 함께 하이바이블의 어린이들과 가정들이 하나님의 말씀과 가까워지기를 소망합니다.',
			keyword: '하이바이블, 징검다리, 성경읽기',
			thumb: '/hibible/resource/images/read_acc_r.svg',
		});
	},
}
</script>