<template>
	<section class="_list">
		<div class="sec_wrap" id="listArea">
			
			<ul id="dataList" class="con_list">
				<bible-item 
					v-for="(item, index) in items" :key="index" 
						:index="index"
						:read-no="item.readNo"
						:book-code="item.bookCode"
						:title="item.title"
						:file-name="item.fileName"
						:fileURL="item.fileS3Link"
				/>
			</ul>
			<nav id="pagingList" class="paging_list">
				<a 
					v-for="(pageItem, index) in pageList" :key="index" 
					:href="pageItem.link" 
					title="link to list" 
					v-on:click="changeList($event, pageItem.link)"
					:class="'paging '+pageItem.css">{{pageItem.code}}</a>
			</nav>
			<div class="search_area" id="searchArea"></div>
		</div>

		<div class="info_area">
			<img :src="$store.state.BASE_URL+'/resource/images/read_acc_l.svg'">
			<div v-html="infoHTML" />
		</div>
	</section>
</template>

<script>
import BibleItem from '@/components/BibleItem';
import axios from 'axios'
import languagePack from '@/language/readBible'


export default {
    name: 'DataList',
	components: {
		BibleItem
	},
    data: () => ({
		items:[],
		pageList:[],
		page:0,
		searchType:'',
		searchKeyword:'',
    }),
	props:{
	},
    computed: {
		lang(){
			return this.$store.state.lang;
		},
		infoHTML(){
			return languagePack.readInfo[this.lang];
		}
    },
	methods: {
		loadData() {
			let vm= this, 
				data= {
					page:vm.page,
					lang:vm.lang,
				}, 
				path=vm.$store.state.API_PATH+'bible';
				if (process.env.NODE_ENV == "development") {
					path+=".json";
				}
			axios.get(
                    path, 
                    {params: data}
                )
                .then((res) =>{
					let data = res.data;
                    if (!data.result) return;
                    vm.items = data.data;
					vm.pageList = vm.buildPageList(data.pagination)
                })
                .catch((error)=>{
                    console.warn(error);
                })
		},
		buildPageList(pageData) {
			var pageGroupEa=5,
				num=pageData.pageTotal,
				pagingS = Math.floor((pageData.page) / pageGroupEa)*pageGroupEa, 
				pagingE = Math.min(num, pagingS+pageGroupEa),
				pageList = [],
				curr;
			pageList.push({code:'|<', link:'0', css:'_first'+(pagingS==0?' _dis':'')});
			pageList.push({code:'<', link:String(Math.max(pagingS-pageGroupEa, 0)), css:'_before'+(pagingS==0?' _dis':'')});
			for (var i=pagingS; i<pagingE; i++) {
				curr = i==pageData.page;
				pageList.push({code:String(i+1), link:(curr?'':''+i), css:'_page'+(curr?' _curr':'')});
			}
			pageList.push({code:'>', link:String(Math.min(pagingS+pageGroupEa, num-1)), css:'_after'+(pagingS+pageGroupEa>num?' _dis':'')});
			pageList.push({code:'>|', link:''+num, css:'_last'+(pagingS+pageGroupEa>num-1?' _dis':'')});
			return pageList;
		},
		changeList(e, pageNo) {
			let vm = this;
			e.preventDefault();
			if (pageNo != vm.page) {
				console.log(pageNo);
				vm.page = pageNo;
				vm.loadData();
			}
		},
	},
	mounted(){
		let vm = this;
		vm.loadData();
	}
}
</script>
