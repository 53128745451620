const lang = localStorage.getItem("lang");
const languagePack = {
	typeName:function(){
		var data = {
			kor:"징검다리 성경읽기", eng:"Stepping Stones Bible Reading", rus:"Чтение Библии (Пошаговые Действия)", nep:"징검다리 성경읽기", tai:"หินรองทางเดิน อ่านพระคัมภีร์", mog:"Гишгүүр чулуу Библи уншлага", bur:"ကျောက်တုံးတံတား ကျမ်းစာဖတ်ခြင်း", kaz:" Киелі қітапті қадаммен оқу"
		}
		return data[lang];
	},
	chapterUnitForm:function(no){
		var data = {kor:"NN과", eng:"Chapter NN", rus:"Номер NN", nep:"Chapter NN", tai:"บทที่ NN", mog:"ДугаарNN", bur:"အခန်း NN", kaz:"NN-Сабақ"};
		return data[lang].split("NN").join(String(no));
	},
	readInfo:{
		kor:`
			<p>
				<b>징검다리 성경읽기</b>는 어린이들과 부모님들을 위한 가정용 성경통독 교재입니다. 
			</p>
			<p>
				창세기부터 요한계시록까지 모든 성경을 읽기 어려운 어린이들에게 매 주일 징검다리처럼 성경을 하나하나 건너가며 각 성경의 주요 내용들을 읽고 그림으로 그려진 문제들을 풀어가면서 성경을 읽을 수 있도록 만들어졌습니다.
			</p>
			<p>
				징검다리 성경읽기는 6년 커리큘럼으로 제작되어 성경의 거의 모든 내용들을 읽을 수 있도록 준비되었으며 매주일 업데이트 되는 교재를 하이바이블 페이지에서 무료로 다운받아서 사용하실 수 있습니다.
			</p>
			<p>
				징검다리 성경 읽기와 함께 하이바이블의 어린이들과 가정들이 하나님의 말씀과 가까워지기를 소망합니다.
			</p>`, 
		eng:`
			<p>
				<b>Stepping Stones, Bible Reading!</b> is a home Bible reading material for parents and children.
			</p>
			<p>
				Reading the entire Bible in order from Genesis to Revelation can be dfficult for children. Stepping Stones, Bible Reading! is organized to facilitate children read the different parts of the Bible every week like stepping stones, focusing on the key events in the Bible through pictures and questions.
			</p>
			<p>
			Stepping Stones, Bible Reading! presents almost all of the events in the Bible throughout its 6-year curriculum. The weekly updated materials can be downloaded for free from the HiBible website.
			</p>
			<p>
			We hope to bring children and families around the world closer to God's word through Stepping Stones, Bible Reading!
			</p>`, 
		rus:`
			<p>
				<b>Пошаговые Действия</b> - это программа чтения Библии для детей и родителей.
			</p>
			<p>
				Пошаговые Действия помогают детям, которым трудно читать Библию  с начала до конца, чтобы они смогли читать ее каждую неделю, и понять Библию, решая поставленные задачи с помощью картинок и заданий.
			</p>
			<p>
				Пошаговые Действия предназначены для чтения всей Библии раз в шесть лет. Вы можете бесплатно скачать еженедельно обновляемые материалы  на домашней странице Hi Bible.
			</p>
			<p>
				Я надеюсь, что благодаря программе, Пошаговые Действия, дети и их родители приблизятся к Слову Божьему.
			</p>`, 
		nep:`
			<p>
				<b>징검다리 성경읽기</b>는 어린이들과 부모님들을 위한 가정용 성경통독 교재입니다. 
			</p>
			<p>
				창세기부터 요한계시록까지 모든 성경을 읽기 어려운 어린이들에게 매 주일 징검다리처럼 성경을 하나하나 건너가며 각 성경의 주요 내용들을 읽고 그림으로 그려진 문제들을 풀어가면서 성경을 읽을 수 있도록 만들어졌습니다.
			</p>
			<p>
				징검다리 성경읽기는 6년 커리큘럼으로 제작되어 성경의 거의 모든 내용들을 읽을 수 있도록 준비되었으며 매주일 업데이트 되는 교재를 하이바이블 페이지에서 무료로 다운받아서 사용하실 수 있습니다.
			</p>
			<p>
				징검다리 성경 읽기와 함께 하이바이블의 어린이들과 가정들이 하나님의 말씀과 가까워지기를 소망합니다.
			</p>`, 
		tai:`
			<p>
				อ่านพระคัมภีร์ "หินรองทางเดิน" คือ 
			</p>
			<p>
				&nbsp;
			</p>
			<p>
				&nbsp;
			</p>
			<p>
				&nbsp;
			</p>`, 
		// tai:`
		// 	<p>
		// 		อ่านพระคัมภีร์ "หินรองทางเดิน" คือ 
		// 	</p>
		// 	<p>
		// 		창세기부터 요한계시록까지 모든 성경을 읽기 어려운 어린이들에게 매 주일 징검다리처럼 성경을 하나하나 건너가며 각 성경의 주요 내용들을 읽고 그림으로 그려진 문제들을 풀어가면서 성경을 읽을 수 있도록 만들어졌습니다.
		// 	</p>
		// 	<p>
		// 		징검다리 성경읽기는 6년 커리큘럼으로 제작되어 성경의 거의 모든 내용들을 읽을 수 있도록 준비되었으며 매주일 업데이트 되는 교재를 하이바이블 페이지에서 무료로 다운받아서 사용하실 수 있습니다.
		// 	</p>
		// 	<p>
		// 		징검다리 성경 읽기와 함께 하이바이블의 어린이들과 가정들이 하나님의 말씀과 가까워지기를 소망합니다.
		// 	</p>`, 
		mog:`
			<p>
				<b>Гишгүүр чулуу библи уншлага</b> нь хүүхдүүд болоод эцэг эхчүүдэд зориулсан гэр бүлийн  библи уншлагын гарын авлага юм.
			</p>
			<p>
				Библийг Эхлэл номоос Илчлэлт номыг дуустал уншихад бэрх хүүхдүүдийн хувьд Эзэний өдөр бүр гишгүүр чулуу мэт Библийг нэг нэгээр алхан ном бүрийн гол утга санааг уншин зургаар илэрхийлсэн даалгаврынг гүйцэтгэсэнээр Библийг унших боломжтой болох юм.
			</p>
			<p>
				Гишгүүр чулуу Библи уншлага нь 6жилийн хөтөлбөрөөр гарсан бараг бүх сэдвийг уншиж чадахуйцаар бэлтгэгдсэн бөгөөд Эзэний өдөр бүр шинэчлэгдэх гарын авлагийг "Сайн уу Библи" цахим хуудаснаас үнэгүй татан авч хэрэглэх боломжтой юм. 
			</p>
			<p>
				Гишгүүр чулуу библи уншлагатай хамт "Сайн уу Библи"-ийн хүүхдүүд болон гэр бүл Бурханы Үгтэй ойртох болтугай.
			</p>`,
		bur:`
			<p>
				ကျမ်းစာဖတ်ခြင်းသည် ကလေးများနှင့် မိဘများအတွက် ရည်ရွယ်သော အိမ်တွင်း သမ္မာကျမ်းဖတ်စာဖြစ်ပါသည်။
			</p>
			<p>
				ကမ္ဘာဉီးကျမ်းမှ ဗျာဒိတ်ကျမ်းအထိ ကျမ်းစာအားလုံးကို ဖတ်ရှုရန် ခက်ခဲသော ကလေးများအတွက် အပတ်စဉ် ခြေနင်း ကျောက်တုံးတံတားလေးကဲ့သို့  တစ်တုံးချင်း တစ်တုံးချင်း ဖြတ်သန်းကာ ကျမ်းစာအပိုင်း တစ်ခုချင်းစီ၏ အကြောင်းအရာများကို ဖတ်ရှုပြီး ပုံဆွဲသားထားသော ပ‌‌ဟေဠိများကို အဖြေရှာရင်း ကျမ်းစာကို ဖတ်ရှုနိုင်စေရန် ‌ပြုစုရေးသားထားပါသည်။
			</p>
			<p>
				ကျောက်တုံးတံတား ကျမ်းစာသည် ခြောက်နှစ်တာသင်ရိုးအဖြစ် ကျမ်းစာ၏ အကြောင်းအရာအားလုံးကို ဖတ်ရှုနိုင်စေရန်  ပြုစုထားကာ အပတ်စဉ် သင်ခန်းစာအသစ်များကို Hi Bible ပေ့ချ်မှတစ်ဆင့် အခမဲ့ ‌‌‌ဒေါင်းလုပ်ရယူနိုင်ပါသည်။
			</p>
			<p>
				ကျောက်တုံးတံတား ကျမ်းစာနှင့်အတူ Hi Bible ၏ ကလေးငယ်လေးများနှင့် မိဘများသည် ဘုရားသခင်၏ နှုတ်ကပတ်တော်နှင့် နီးစပ်လာစေရန် မျှော်လင့်ပါသည်။
			</p>
		`,
		kaz:`
			<p>
				Киелі кiтапты қадаммен оқу - - балалар мен ата-аналарға арналған. Үйде оқуға арналған Киелі кiтап оқулығы.
			</p>
			<p>
				Балаларға Жаратылыстың басталуынан бастап Аян кiтабына дейін Киелі кiтапты бастан-аяқ оқу қиын болады. Балалар Киелі кiтапты әр апта сайын баспалдақ сияқты бiр-бiрден қадамнан-қадамға жүрiп, әр кiтаптың негізгі мазмұнын оқу арқылы және суреттерге салынған есептерді шешу арқылы Киелі кiтапты оқи алады.
			</p>
			<p>
				Қиелі қітапты қадаммен оқу - 6 жылдық бағдалама. Қиелі қітаптың мазмұнын барлық дерлік оқуға дайындалған. Сіз апта сайын жаңарып тұратын оқулықтарды HiBible сайттан жүктеп алып, қолдана аласыз.
			</p>
			<p>
				Қиелі қітапты қадаммен оқу бағдаламасы арқылы балаларыңызбен отбасыңыздың Құдай сөзіне жақындауыңызды қалаймыз.
			</p>
		`,
	}
}
export default languagePack
